import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const TopMenu = ({ items, customStyles }) => {
  const currentPage = window.location.pathname.substring(1);
  const [openDropdown, setOpenDropdown] = useState(null);
  const {type, number} = customStyles
  console.log("customStyles:  type:",type, " number:",number)
  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (openDropdown !== null && !e.target.closest('.dropdown-wrapper')) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [openDropdown]);

  const handleDropdownToggle = (index) => {
    setOpenDropdown(index);
  };

  return (
    <nav role="navigation" className="navigation-items top-items w-nav-menu">
      {items.map((item, index) => (
        <div key={index} className="dropdown-wrapper">
          {item.items && item.items.length > 0 ? (
            <>
              <Link to={"/" + item.DT_Setting_URL}>
                <button
                  className={
                    currentPage === item.DT_Setting_URL
                    ? `navigation-item nav-${type}${number}-item w-nav-link w--current`
                    : `navigation-item nav-${type}${number}-item w-nav-link`
                  }
                  onMouseEnter={() => handleDropdownToggle(index)}
                  
                  >
                  {item.DT_Setting}
                </button>
              </Link>
              {openDropdown === index && (
                <div className="dropdown-content">
                  {item.items.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={"/" + subItem.DT_Setting_URL}
                      className={`dropdown-item nav-${type}${number}-dropdown`}
                    >
                      {subItem.DT_Setting}
                    </Link>
                  ))}
                </div>
              )}
            </>
          ) : (
            <Link
              to={"/" + item.DT_Setting_URL}
              className={
                currentPage === item.DT_Setting_URL
                  ? `navigation-item nav-${type}${number}-item w-nav-link w--current`
                  : `navigation-item nav-${type}${number}-item w-nav-link`
              }
            >
              {item.DT_Setting}
            </Link>
          )}
        </div>
      ))}
    </nav>
  );
};

export default TopMenu;