export const getDomainData = () => {
    //fetch('http://185.151.31.204:3023/api/data?domain=' + window.location.hostname.replace("www.","").replace(/(^\w)|([-\s]\w)/g, match => match.toUpperCase()),{
    fetch('http://185.151.31.204:3023/api/data?domain=' + "tn31.co.uk",{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      return data[0]
    })

    .catch(error => console.error(error));
}

export const getRoot = (str) => {
  const regex = /(?:\.|^)([^.]+)\.(?:co\.uk|uk|com)$/;
  const match = str.match(regex);

  if (match && match.length > 1) {
    return match[1];
  }

  return null;
}

