import '../../assets/css/main.css';
import '../../assets/css/normalize.css'
import {Link} from "react-router-dom"
import { useFormatMessage } from '../../hooks';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import Navigation from '../../components/Nav';
import JsxParser from 'react-jsx-parser';
import { getRoot } from '../../services';
import Footer from '../../components/Footer';
import RssFeed from '../../components/rssFeed';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';  
import { awcg_domain, awcg_domain_u, awcg_domain_u1, awcg_root, awcg_root_u, awcg_root_u1 } from '../../utils';
import AW_DataWrapper from '../../components/AW_DataWrapper';
import { useSelector } from 'react-redux';




function DynamicPage(props) {
  const column = props.defaultColumns
  const [columnLeftWidth, setColumnLeftWidth] = useState(props.leftJSX?.width || column?.left?.width || 25)
  const [columnLeftData, setColumnLeftData] = useState(props.leftJSX?.data || column?.left?.content || undefined)
  const [columnRightWidth, setColumnRightWidth] = useState(props.rightJSX?.width || column?.right?.width || 25)
  const [columnRightData, setColumnRightData] = useState(props.rightJSX?.data || column?.right?.content || undefined)
  const awcg_passedData = useSelector((state) => state.dynamicData)
  //Scroll to anchor support for react-router-dom 
  useEffect(() => {
    const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  //On page scroll to anchor
  const ScrollLink = ({ to, children }) => (
    <Link
      style={{ textDecoration: 'none' }}
      to={to.pathname}
      onClick={() => {
        const element = document.getElementById(to.hash.slice(1)); // Remove the '#' character from the anchor name
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }}
    >
      {children}
    </Link>
  );

  return (
    <div className='page-body'>
      <Helmet>
        <title>{awcg_domain_u1} | {props.title}</title>
        <meta name="description" content={`Explore ${awcg_domain_u1}'s ${props.title} page`}/> 
      </Helmet>
      <Link to="/domain-for-sale" style={{ textDecoration: 'none' }}>
        <div className="domain-forsale wf-section">
          <div className="div-block">
            <div className="domain-forsale-text">{awcg_domain_u1} {useFormatMessage('home.forsaletext')}<span className="text-span"> {useFormatMessage('home.sale')}</span> {"("}{useFormatMessage('home.enquire')} <span style={{textDecoration: "underline"}}>{useFormatMessage('home.here')}</span>{")"}</div>
          </div>
        </div>
      </Link>
      <Navigation />
      <div className="section">
        <div className="section-wrapper">
          <div className="column column-left" style={{ width: `${columnLeftWidth}%` }}>
            {columnLeftData == undefined?
              <h4>*</h4>
              :
              <>
                <JsxParser
                bindings={{
                  awcg_root:awcg_root,
                  awcg_root_u:awcg_root_u,
                  awcg_root_u1:awcg_root_u1,
                  awcg_domain:awcg_domain,
                  awcg_domain_u:awcg_domain_u,
                  awcg_domain_u1:awcg_domain_u1,
                  awcg_passedData:awcg_passedData,
                  myEventHandler: () => { /* ... do stuff ... */ },
                }}
                components={{AW_DataWrapper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, ScrollLink, RssFeed}}
                jsx={columnLeftData}
                />
              </>
            }
          </div>
          <div className="column center">
            {props.centerJSX == undefined?
              <h1>*</h1>
              :
              <div>
                <div>
                  <h1>{props.title}</h1>
                  {props.data.items && props.data.items.length !== 0 && <div style={{flexDirection: "row", flex: 1, marginBottom: 20, marginTop: -20}}>
                    {props.data.items.map((item, index) =>
                      <Link to={`/${item.DT_Setting_URL}`} style={{marginRight: 10}}>
                      <p>{item.DT_Setting}</p>
                      </Link>
                    )}
                  </div>}
                </div>
                <JsxParser
                bindings={{
                  awcg_root:awcg_root,
                  awcg_root_u:awcg_root_u,
                  awcg_root_u1:awcg_root_u1,
                  awcg_domain:awcg_domain,
                  awcg_domain_u:awcg_domain_u,
                  awcg_domain_u1:awcg_domain_u1,
                  awcg_passedData:awcg_passedData,
                  myEventHandler: () => { /* ... do stuff ... */ },
                  Paper: Paper
                }}
                components={{AW_DataWrapper,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, ScrollLink, RssFeed}}
                jsx={props.centerJSX}
                />
              </div>
            }
          </div>
          <div className="column column-right" style={{ width: `${columnRightWidth}%` }}>
            {columnRightData == undefined?
              <h4>*</h4>
              :
              <>
                <JsxParser
                bindings={{
                  awcg_root:awcg_root,
                  awcg_root_u:awcg_root_u,
                  awcg_root_u1:awcg_root_u1,
                  awcg_domain:awcg_domain,
                  awcg_domain_u:awcg_domain_u,
                  awcg_domain_u1:awcg_domain_u1,
                  awcg_passedData:awcg_passedData,
                  myEventHandler: () => { /* ... do stuff ... */ },
                }}
                components={{AW_DataWrapper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Link, ScrollLink, RssFeed}}
                jsx={columnRightData}
                />
              </>
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DynamicPage;

