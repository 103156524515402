import { Helmet } from 'react-helmet';
import { useFormatMessage } from '../../hooks';
import { awcg_domain_u1 } from '../../utils';
import Footer from '../../components/Footer';

function Domain() {
  return (
    <>
      <Helmet>
        <title>{awcg_domain_u1} | For Sale</title>
      </Helmet>
      <div class="fullscreen-sperator">
        <div>
          <div class="domain-nav">
            <div class="domain-nav-wrapper">
              <h1 class="any-domains">ANY-Domains.com</h1>
            </div>
          </div>
          <div class="domain-content">
            <div class="domain-content-wrapper">
              <div class="domain-content-block">
                <div class="domain-content-text">{useFormatMessage("domain.first")}</div>
                <h1 class="domain-name">{awcg_domain_u1}</h1>
                <div class="domain-content-text">{useFormatMessage("domain.second")}</div>
              </div>
              <div class="domain-content-block right">
                <a className="button-2 w-button" href={`mailto:info@any-domains.uk?subject=Enquiring for ${awcg_domain_u1}`} target="_blank" rel="noopener">{useFormatMessage("domain.enquire")}</a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Domain