import { createReducer } from 'redux-act';

import { DYNAMICDATA_SET_DATA } from '../../actions/dynamicData';

const initialState = {
  data: null
};

export const dynamicDataReducer = createReducer(
  {
    [DYNAMICDATA_SET_DATA]: (state, payload) => ({
      ...state,
      [payload.dataTopic]: payload.data
    })
  },
  initialState
);
