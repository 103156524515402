import { useEffect } from 'react';

const useDynamicCSS = (cssFilePath) => {
  useEffect(() => {
    if(cssFilePath !== undefined){
      console.log("getting CSS CONTENT::::::::::::::.")
      const link = document.createElement('link');
      link.href = 'https://tablesdirect.co.uk/_awt/css/' + cssFilePath;
      link.rel = 'stylesheet';
      link.type = 'text/css'
      
      document.head.appendChild(link);
      return () => {
        // Cleanup the dynamically added stylesheet when the component is unmounted
        document.head.removeChild(link);
    }
    };
  }, [cssFilePath]);
};

export default useDynamicCSS;