import { createReducer } from 'redux-act';
import { RSSDATA_SET_DATA } from '../../actions/rssData';

const initialState = {
  data: [],
};

export const rssDataReducer = createReducer(
  {
    [RSSDATA_SET_DATA]: (state, payload) => {
      // Check if the item already exists in the array
      const existingItemIndex = state.data.findIndex((item) => item.id === payload.id);

      if (existingItemIndex !== -1) {
        // If it exists, update the existing item
        const newData = [...state.data];
        newData[existingItemIndex] = { id: payload.id, lastUpdate: payload.lastUpdate, data: payload.data };
        return {
          ...state,
          data: newData,
        };
      } else {
        // If it doesn't exist, add it to the array
        return {
          ...state,
          data: [...state.data, { id: payload.id, lastUpdate: payload.lastUpdate, data: payload.data }],
        };
      }
    },
  },
  initialState
);