import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-wrapper">
            <div class="container-2">
                <div class="footer-divider-two"></div>
                <p class="disclaimer">Disclaimer: ANY-Web Ltd. cannot be held responsible for any problems or losses encountered as a result of your use of its sites. Please read our <a href="https://www.any-web.uk/legal_disclaimer.aspx" class="inlineTextLink">Full Disclaimer</a> and <a href="https://www.any-web.uk/legal_privacy.aspx" class="inlineTextLink">Privacy Statement</a> for more details. Your use of our sites is bound by their terms. Also see our <a href="https://www.any-web.uk/legal_antispam.aspx" class="inlineTextLink">Anti-Spam</a> and <a href="https://www.any-web.uk/legal_copyright.aspx" class="inlineTextLink">Copyright</a> notices.</p>
                <div class="footer-bottom">
                    <div class="footer-copyright">© 2023 ANY-Web Ltd. All rights reserved</div>
                    <div class="footer-social-block-two">
                    <a href="#" class="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ac76f12aaec_product%20icon-2.svg" loading="lazy" alt=""/></a>
                    <a href="#" class="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a4a9512aae0_product%20icon-1.svg" loading="lazy" alt=""/></a>
                    <a href="#" class="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a853712aad7_product%20icon.svg" loading="lazy" alt=""/></a>
                    </div>
                </div>
            </div>
            {/* <div>
                <a href="https://any-web.uk/" target="_blank" className="webflow-link w-inline-block">
                    <img src="images/webflow-w-small2x_1webflow-w-small2x.png" width="15" alt="" className="webflow-logo-tiny"/>
                    <div className="paragraph-tiny">ANY-Web Limited</div>
                </a>
                <div className="webflow-link w-inline-block">
                    <div className="paragraph-tiny"> 
                        Disclaimer: ANY-Web Ltd. cannot be held responsible for any problems or losses encountered as a result of your use of its sites. Please read our <span><a href="https://webflow.com/" target="_blank" className="webflow-link w-inline-block">Full Disclaimer</a></span> and Privacy Statement for more details. Your use of our sites is bound by their terms. Also see our Anti-Spam and Copyright notices.
                    </div>
                </div>
            </div> */}
        </div>
    </div>
  )
}

export default Footer