import { createReducer } from 'redux-act';

import { DOMAINDATA_SET_DATA } from '../../actions/domainData';

const initialState = {
  data: null
};

export const domainDataReducer = createReducer(
  {
    [DOMAINDATA_SET_DATA]: (state, payload) => ({
      ...state,
      data: payload.data
    })
  },
  initialState
);
