import React, { useEffect, useState } from 'react';
import { setRSSData } from '../../state/actions/rssData';
import { useDispatch, useSelector } from 'react-redux';
import Countdown from '../Countdown';
import { Link } from 'react-router-dom';

const RssFeed = ({link, layout}) => {
  // const layout ={title: "title", link: "link", description: "description", pubDate: {id: "pubDate", format:"timeSince"} }
  const dispatch = useDispatch()
  const [items, setItems] = useState([]);
  const storedRSSData = useSelector((state) => state.rssData.data);

  useEffect(() => {
    // Check if data is already in the Redux store
    const storedItem = storedRSSData.find((item) => item.id === link);

    if (storedItem && Date.now() - storedItem.lastUpdate < 3600000) {
      // Use stored data if available and not older than 1 hour
      console.log("Found Valid RSS Data")
      setItems(storedItem.data);
    } else {
      console.log("Fetching new Data")
      // Fetch data if not available or older than 1 hour
      fetch('https://any-dns.co.uk/aw_api/api/rss?url=' + link)
        .then((response) => response.text())
        .then((data) => {
          const parser = new DOMParser();
          const feed = parser.parseFromString(data, 'text/xml');
          const items = Array.from(feed.querySelectorAll('item')).map((item) => {
            const title = item.querySelector(layout.title)?.textContent;
            const link = item.querySelector(layout.link)?.textContent;
            const description = item.querySelector(layout.description)?.textContent;
            const pubDate = item.querySelector(layout.pubDate.id)?.textContent;
            return {
              title,
              link,
              description,
              pubDate,
            };
          });
          setItems(items);
          // Dispatch action to update Redux store
          dispatch(setRSSData(link, Date.now(), items));
        })
        .catch(console.error);
    }
  }, [link, dispatch, storedRSSData]);

  return (
    <div>
      {items.map((item, index) => (
        <div style={{backgroundColor: "#f5f5f5", padding: 5, marginBottom: 5}} key={index}>
          <a target='_blank' rel='noopener noreferrer' href={item.link}>
            <h6>{item.title}</h6>
          </a>
          {layout?.pubDate && <Countdown pubDate={item.pubDate} format={layout.pubDate.format} />}
        </div>
      ))}
    </div>
  );
};

export default RssFeed;