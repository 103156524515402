import {Link, useLocation} from "react-router-dom";
import { useFormatMessage } from '../../hooks';
import { Helmet } from 'react-helmet';
import Navigation from '../../components/Nav';
import Footer from '../../components/Footer';
import JsxParser from "react-jsx-parser";
import { getRoot } from "../../services";
import { useEffect } from "react";
import ScrollLink from "../../components/Link";



function Search(props) {
    const awcg_root = getRoot(window.location.hostname);
    const awcg_domain = getRoot(window.location.hostname);
    const { search } = useLocation();
    const query = new URLSearchParams(search).get('q');
    const domain = window.location.hostname.replace("www.","").replace(/(^\w)|([-\s]\w)/g, match => match.toUpperCase())

    //Scroll to anchor support for react-router-dom 
    useEffect(() => {
      const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, []);

    return (
        <div className='page-body'>
            <Helmet>
                <title>{domain} | Home</title>
            </Helmet>
            <Link to="domain-for-sale" style={{ textDecoration: 'none' }}>
                <div className="domain-forsale wf-section">
                <div className="div-block">
                    <div className="domain-forsale-text">{domain} {useFormatMessage('home.forsaletext')}<span className="text-span"> {useFormatMessage('home.sale')}</span> {"("}{useFormatMessage('home.enquire')} <span style={{textDecoration: "underline"}}>{useFormatMessage('home.here')}</span>{")"}</div>
                </div>
                </div>
            </Link>
            <Navigation />
            <div className="section">
                <div className="section-wrapper">
                    <div className="column">
                        {props.leftJSX == undefined?
                        <h4>*</h4>
                        :
                        <>
                            <JsxParser
                            bindings={{
                            awc_root: awcg_root,
                            foo: 'bar',
                            myEventHandler: () => { /* ... do stuff ... */ },
                            }}
                            components={{Link, ScrollLink}}
                            jsx={props.leftJSX}
                            />
                        </>
                        }
                    </div>
                    <div className="column center">
                        <h1>We have found the following results for {query}</h1>
                    </div>
                    <div className="column">
                        {props.rightJSX == undefined?
                        <h4>*</h4>
                        :
                        <>
                            <JsxParser
                            bindings={{
                            awcg_root: awcg_root,
                            foo: 'bar',
                            myEventHandler: () => { /* ... do stuff ... */ },
                            }}
                            components={{}}
                            jsx={props.rightJSX}
                            />
                        </>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Search;
