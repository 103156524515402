/* eslint-disable */
import { createIntl, createIntlCache } from 'react-intl';

import english from '../languages/en';
import deutsch from '../languages/de';
import italiano from '../languages/it';



export const messages = {
  en: english,
  de: deutsch,
  it: italiano,
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];


export const getFormat = (str, root) => {
   // Split the string by "." and store the parts in an array
   let parts = str.split(".");
   // Get the length of the array
   let len = parts.length;
   // Initialize the format variable
   let format = "";
   // If the length is 1, the format is just the domain
   if (len == 1) {
     format = parts[0];
   }
   // If the length is 2, the format is the domain and the top-level domain
   else if (len == 2) {
     root? format = parts[0] :format = parts[0] + "." + parts[1];
   }
   // If the length is 3 or more, the format is the domain and the top-level domain
   else {
     // Check if the last part is "uk" and the second last part is "co" or "org"
     if (parts[len - 1] == "uk" && (parts[len - 2] == "co" || parts[len - 2] == "org")) {
       // The top-level domain is two parts
       root? format = parts[len - 3] : format = parts[len - 3] + "." + parts[len - 2] + "." + parts[len - 1];
     } else {
       // The top-level domain is one part
       root? format = parts[len - 2] : format = parts[len - 2] + "." + parts[len - 1];
     }
   }
   // Return the format
   return format;
 }

 export const awcg_root = getFormat(window.location.hostname,true)
 export const awcg_root_u = awcg_root.toUpperCase();
 export const awcg_root_u1 = awcg_root.replace(/(^\w)|([-\s]\w)/g, match => match.toUpperCase())

 export const awcg_domain = getFormat(window.location.hostname,false)
 export const awcg_domain_u = awcg_domain.toUpperCase();
 export const awcg_domain_u1 = awcg_domain.replace(/(^\w)|([-\s]\w)/g, match => match.toUpperCase())
