import React, { useState, useEffect } from 'react';

const Countdown = ({ pubDate, format }) => {
    const calculateTimeSince = (pubDate) => {
      
        const currentDate = new Date();
        const pubDateObj = new Date(pubDate);
        const timeDifference = currentDate - pubDateObj;
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        if (minutes < 60) {
            return `${minutes} min ago`;
        } else if (hours === 1) {
            return '1 hour ago';
        } else {
            return `${hours} hours ago`;
        }
    };
    const [timeSince, setTimeSince] = useState(calculateTimeSince(pubDate));
    const [showCountdown, setShowCountdown] = useState(format === 'timeSince');
    useEffect(() => {
      const interval = setInterval(() => {
        setTimeSince(calculateTimeSince(pubDate));
      }, 60000); // Update every minute
  
      return () => clearInterval(interval);
    }, [pubDate]);
  
  
    return <p>{showCountdown ? timeSince : pubDate}</p>;
  };
  
export default Countdown;