
import React from 'react'
import { Link } from 'react-router-dom';

const ScrollLink = ({ to, children }) => (
    <Link
      style={{ textDecoration: 'none' }}
      to={to.pathname}
      onClick={() => {
        const element = document.getElementById(to.hash.slice(1)); // Remove the '#' character from the anchor name
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }}
    >
      {children}
    </Link>
  );

export default ScrollLink